import APICONFIGS from '../admin-api-configs.json'
import { UrlHelpers, customFetch, authHeader, jsonHeader } from '../../helpers'

export const systemService = {
  getConfig,
  getAllConfigs,
  updateContact,
  updateBankTranfer,
  updateGeneralSettings,
  updateCommentInExamSettings,
  getServerTimeZone,
}

function getConfig(code) {
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader(),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.SystemVariables, code)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function getAllConfigs() {
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader(),
  }
  let apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, APICONFIGS.SystemVariables)
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function updateContact(model) {
  let requestOptions = {
    method: 'PUT',
    cache: 'no-cache',
    body: JSON.stringify(model),
    headers: authHeader(jsonHeader()),
  }
  let apiUrl = UrlHelpers.combine(
    process.env.REACT_APP_BASEURL,
    APICONFIGS.SystemVariables,
    'contact',
  )
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function updateBankTranfer(model) {
  let requestOptions = {
    method: 'PUT',
    cache: 'no-cache',
    body: JSON.stringify(model),
    headers: authHeader(jsonHeader()),
  }
  let apiUrl = UrlHelpers.combine(
    process.env.REACT_APP_BASEURL,
    APICONFIGS.SystemVariables,
    'bank-transfer',
  )
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function updateGeneralSettings(model) {
  let requestOptions = {
    method: 'PUT',
    cache: 'no-cache',
    body: JSON.stringify(model),
    headers: authHeader(jsonHeader()),
  }
  let apiUrl = UrlHelpers.combine(
    process.env.REACT_APP_BASEURL,
    APICONFIGS.SystemVariables,
    'general',
  )
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function getServerTimeZone() {
  let requestOptions = {
    method: 'GET',
    cache: 'no-cache',
    headers: authHeader(jsonHeader()),
  }
  const apiUrl = UrlHelpers.combine(process.env.REACT_APP_BASEURL, 'api/server-time-zone')
  return customFetch(apiUrl, requestOptions).then((res) => res)
}

function updateCommentInExamSettings(model) {
  let requestOptions = {
    method: 'PUT',
    cache: 'no-cache',
    body: JSON.stringify(model),
    headers: authHeader(jsonHeader()),
  }
  let apiUrl = UrlHelpers.combine(
    process.env.REACT_APP_BASEURL,
    APICONFIGS.SystemVariables,
    'comment-in-exams',
  )
  return customFetch(apiUrl, requestOptions).then((res) => res)
}
